@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 24.6 95% 53.1%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 24.6 95% 53.1%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 72.2% 50.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 20.5 90.2% 48.2%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
}

@layer base {
  body {
    @apply bg-gray-50 text-foreground;
  }

  textarea {
    @apply resize-none;
  }

  h1 {
    @apply font-semibold;
  }

  h2, h3, h4 {
    @apply font-medium;
  }

  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-lg;
  }

  a {
    @apply text-primary no-underline;
  }

.markdown-body {
  ul li {
    @apply list-disc;
  }

  ol li {
    @apply list-decimal;
  }

}

[multiple],
[type=date],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
  @apply px-3 py-2 leading-normal border appearance-none;
}
}

@layer components {
  .app-upload {
    @apply border-2 border-gray-300 border-dashed rounded-md cursor-pointer select-none;
  }

  .app-input {
    @apply block w-full border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-orange-400 focus:border-orange-400;
  }

  .app-textarea {
    @apply h-full p-4 bg-gray-50 app-input focus:bg-white;
  }

  .app-button {
    @apply px-6 py-1 text-gray-600 bg-white border border-gray-300 rounded-full shadow-sm cursor-pointer hover:border-orange-400 hover:text-orange-600 hover:ring-2 hover:ring-orange-500;
  }

  .app-button-primary {
    @apply px-6 py-1 bg-orange-500 border border-gray-300 rounded-full shadow-sm cursor-pointer text-gray-50 hover:border-orange-400 hover:text-orange-600 hover:bg-white hover:ring-2 hover:ring-orange-500;
  }

  .app-tag {
    @apply px-1 py-1 mr-3 bg-gray-100 border border-gray-300 rounded-sm cursor-pointer;
  }

  .two-columns {
    @apply grid grid-cols-1 gap-4 font-mono text-lg rounded md:grid-cols-2
  }
}


div.cm-s-solarized.CodeMirror {
  box-shadow: none;
}

.CodeMirror {
  @apply !h-full p-4 bg-transparent;
}
